import React from 'react'
import styled from 'styled-components'

import HeaderNav from '../navigation/HeaderNav'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7bd01;
  width: 100%;
  height: 140px;
`

const PageHeroHeader = () => (
  <StyledDiv>
    <HeaderNav />
  </StyledDiv>
)

export default PageHeroHeader