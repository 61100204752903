import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import PageHeroHeader from '../components/headers/PageHeroHeader'
import HeaderNav from '../components/navigation/HeaderNav'
import PageTermsSection from "../components/sections/PageTermsSection"

export default () => (
  <HomeContainer>
    <HeadData title='Terms' />

    <PageHeroHeader>
      <HeaderNav isHome />
    </PageHeroHeader>

    <PageTermsSection />
  </HomeContainer>
)